






























































import { Chair } from "@/types";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ChairDetailCard",
  props: {
    chair: {
      type: Object as PropType<Chair>,
      required: true,
    },
  },
  mounted() {
    this.isLargeDescription =
      this.$helpers.removeTags(this.chair.description).length > 700;
  },
  computed: {
    cleanedFields: function (): { text: string; value: string }[] {
      return this.fields.filter(
        (x: { text: string; value: string }) => !!x.value
      );
    },
    mediumImages: function (): string[] {
      let allImages = this.chair.chairadditionalpictures.map(
        (x) => x.display_medium
      );
      allImages.unshift(this.chair.display_medium);
      return allImages;
    },
    provenance: function (): string {
      return this.$helpers.removeTags(this.chair.provenance);
    },
    cleanDescription: function (): string {
      let description = this.$helpers.removeTags(this.chair.description);
      if (!description) {
        return "";
      }
      if (!this.isLargeDescription) {
        return description;
      }
      description = this.shortDescription
        ? description.substring(0, 500) + "..."
        : description;
      return description;
    },
  },
  data: function () {
    return {
      fields: [
        {
          text: "Designer",
          value: this.$helpers.getDesigners(this.chair.designers),
        },
        { text: "Designed", value: this.chair.design_year },
        { text: "Made", value: this.chair.object_year },
        { text: "Manufacturer", value: this.chair.manufacturer },
        { text: "Model number", value: this.chair.model_number },
        { text: "Material", value: this.chair.material_description },
        { text: "Dimensions", value: this.chair.dimension },
        { text: "Inventory", value: this.chair.inventoryNumber },
      ],
      shortDescription: true,
      isLargeDescription: false,
    };
  },
});
