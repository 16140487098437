




















import Vue from "vue";
import { API_URL_CHAIRS } from "@/config";
import DetailView from "@/components/general/DetailView.vue";
import ChairDetailCard from "@/components/chair/ChairDetailCard.vue";
import RelatedItems from "@/components/general/RelatedItems.vue";
import ExhibitionHistory from "@/components/general/ExhibitionHistory.vue";

export default Vue.extend({
  name: "ChairDetail",
  components: { RelatedItems, ChairDetailCard, DetailView, ExhibitionHistory },
  data() {
    return {
      API_URL_CHAIRS,
    };
  },
});
